import React, { FC } from 'react';
import './TipsForYouPage.scss';
import { graphql } from 'gatsby';
import Layout from 'layout/Layout';
import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';
import Banner from 'common/Banner';
import ArticleList from 'components/ArticleList';
import SignUpBanner from 'components/SignUpBanner';

const TipsForYouPage: FC<TipsForYouTypes.TipsForYouProps> = ({
  data: {
    allUmbracoTipsForYou: { nodes: umbracoTipsForYouNodes },
    allUmbracoArticles: { nodes: umbracoArticlesNodes },
  },
}) => {
  const {
    langProps: { urls, lang },
    seoProps: { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs, ogImage },
    articlesMainBanner: { content, bannerVariant, imageAsBackground, imageAsBackgroundAlt },
    articleListSettings,
  } = umbracoTipsForYouNodes[0];

  return (
    <Layout langProps={{ urls, lang }}>
      <Seo
        {...{
          title: seoMetaTitle,
          description: seoMetaDescription,
          keywords: seoMetaKeywords,
          externalHreflangs: seoExternalHreflangs,
          ogImage,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <Banner
        {...{
          content,
          bannerVariant,
          imageAsBackground,
          imageAsBackgroundAlt,
        }}
      />
      <ArticleList {...{ articleListSettings, umbracoArticlesNodes, showAllArticles: true }} />
      <SignUpBanner {...{ lang }} />
    </Layout>
  );
};

export const query = graphql`
  query TipsForYouQuery(
    $tipsForYouId: String
    $parentId: Int
    $sortOrder: [SortOrderEnum]
    $sortName: [UmbracoArticlesFieldsEnum]
    $lang: String
  ) {
    allUmbracoTipsForYou(filter: { id: { eq: $tipsForYouId } }) {
      nodes {
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
          ogImage
        }
        articleListSettings {
          sectionTitle
          loadAllArticlesButtonText
          moreText
          moreAriaLabel
          numberOfArticles
          isLabelsActive
        }
        articlesMainBanner {
          content
          bannerVariant
          imageAsBackgroundAlt
          imageAsBackground {
            fallbackUrl
            fluid {
              srcSet
              originalImg
              base64
            }
            variants {
              url
              type
              fallbackQuery
              alias
            }
          }
        }
      }
    }
    allUmbracoArticles(
      filter: { parentId: { eq: $parentId }, langProps: { lang: { eq: $lang } } }
      sort: { fields: $sortName, order: $sortOrder }
    ) {
      nodes {
        langProps {
          lang
        }
        articleThumbnailImage {
          fallbackUrl
          fluid {
            base64
            originalImg
            srcSet
          }
          variants {
            url
            type
            fallbackQuery
            alias
          }
        }
        articleBannerImageAltText
        url
        title
        teaser
        label
        tags {
          articleTag
          id
        }
      }
    }
  }
`;

export default TipsForYouPage;
